/** @jsx jsx */
import React, { useEffect } from 'react';
import { jsx } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';
import AdoptEmbryoButton from './AdoptEmbryoButton';
import DonateEmbryoButton from './DonateEmbryoButton';

const Header = ({
  title,
  ctaPrimary = 'Adopt Embryos',
  ctaSecondary = 'Donate Embryos',
  ctaSecondaryLink = 'https://members.embryodonation.org/',
  description,
  video,
  background,
}) => {
  function youTubeGetID(url) {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
  }

  const id = video && youTubeGetID(video);

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const Plyr = require('plyr');
      Array.from(document.querySelectorAll('.js-player')).map((p) => new Plyr(p));
    }
  }, [video]);

  const data = useStaticQuery(graphql`
    query {
      space: degaSpace {
        description
        meta_fields
        fav_icon {
          url
          dimensions
        }
      }
    }
  `);

  const { space } = data;

  return (
    <header
      sx={{
        background: '#4B938EE5',
      }}
    >
      <div
        sx={{
          maxWidth: '1920px',
          mx: 'auto',
          display: 'flex',
          flexWrap: 'wrap',
          // py: ['2rem', '4rem', null, null, '0'],
          //  px: ['1rem', null, '2rem', null, '0'],
          height: '100%',
          // minHeight: [550, null, null, '0'],
          position: 'relative',
        }}
      >
        <div
          sx={{
            flex: ['1 0 100%', null, '1 0 50%'],
            maxWidth: ['100%', null, '50%'],
            position: 'relative',
          }}
        >
          <img
            src={background || '/images/homepage.jpeg'}
            alt=""
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
          <div
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: '#4B938EE5',
            }}
          ></div>
          <div
            sx={{
              position: 'relative',
              zIndex: '1',
              height: '100%',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              alignItems: 'center',
              justifyContent: 'center',
              p: '3rem 2rem',
            }}
          >
            <h1
              sx={{
                fontSize: ['2rem', null, null, '3.75rem'],

                color: '#FFFFFF',
              }}
            >
              {title}
            </h1>
            {description && (
              <p
                sx={{
                  variant: 'text.normal',
                  letterSpacing: '0.09em',
                  color: '#FFFFFF',
                  textTransform: 'uppercase',
                }}
              >
                {description}
              </p>
            )}{' '}
            <div
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                gap: '1rem',
              }}
            >
              {ctaPrimary && (
                <AdoptEmbryoButton
                  style={{
                    display: 'inline-block',
                    fontSize: '1rem',
                    color: ' #FFFFFF',
                    p: '1rem 1.5rem',
                    background: ' #F7A496',
                    border: '1px solid #F7A496',
                    borderRadius: '8px',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    minWidth: '200px',
                    textAlign: 'center',
                    my: 0,
                    '&:hover': {
                      bg: 'transparent',
                    },
                  }}
                />
              )}

              {ctaSecondary && (
                <DonateEmbryoButton
                style={{
                  display: 'inline-block',
                  fontSize: '1rem',
                  color: ' #FFFFFF',
                  p: '1rem 1.5rem',
                  border: '1px solid #fff',
                  borderRadius: '8px',
                  marginTop: '1rem',
                  marginBottom: '1rem',
                  minWidth: '200px',
                  textAlign: 'center',
                  '&:hover': {
                    color: '#F7A496',
                    borderColor: '#F7A496',
                  },
                }}
                />
              )}
            </div>
          </div>
          {space?.meta_fields?.baby_counter && (
            <div
              sx={{
                display: ['none', null, null, 'flex'],
                flexDirection: 'column',
                alignItems: 'center',
                position: 'absolute',

                bottom: '-5rem',
                left: '50%',
                transform: 'translateX(-50%)',
                background: '#fff',
                borderRadius: '5px',
                fontSize: '1.375rem',
                textTransform: 'uppercase',
                zIndex: 1,
                textAlign: 'center',

                padding: ['1rem', null, null, null, null, '2rem'],
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              }}
            >
              <h3 sx={{ fontSize: ['1rem', null, null, null, '1.125rem'], color: '#629B98' }}>
                NEDC BABY COUNTER
              </h3>
              <h4
                sx={{
                  color: '#000',
                  fontSize: '3rem',
                  display: 'flex',
                  gap: '1rem',
                  alignItems: 'center',
                }}
              >
                {space.meta_fields.baby_counter}
                <img src="/images/babies.svg" sx={{ width: '80px' }} alt="" />{' '}
              </h4>
            </div>
          )}
          <div></div>
        </div>

        <div
          sx={{
            flex: ['1 0 100%', null, '1 0 50%'],
            maxWidth: ['100%', null, '50% '],
            p: 0,
          }}
        >
          {video && (
            <div
              className="js-player"
              data-plyr-provider="youtube"
              data-plyr-embed-id={id}
              sx={{ width: '100%', height: '100%' }}
            />
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
