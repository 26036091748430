/** @jsx jsx */
import * as React from 'react';
import Header from '../components/Common/Header';
import Intro from '../components/Support/Intro';
import PlannedGiving from '../components/Support/PlannedGiving';
import Layout from '../components/Layout/index';
import { Seo } from '../components/Seo';
import { jsx } from 'theme-ui';

const SupportPage = () => {
  return (
    <Layout>
      <Seo
        title="Support the NEDC | National Embryo Donation Center"
        description="We always need the support of people like you in our mission of protecting the lives and dignity of human embryos. Won't you make a difference for families?"
      />
      <div>
        <Header
          title={'Support the NEDC'}
          description="Giving Life. Giving Hope. Building Families."
          video={'https://youtu.be/Nn7ILdEfLBI'}
          background={'/images/header/kelly-sikkema-4le7k9XVYjE-unsplash.png'}
        />
        <Intro />
        <div sx={{ maxWidth: '1400px', mx: 'auto', my: '2rem' }}>
          <iframe
            title="donation-form"
            style={{ height: '600px', width: '95%', border: '1px solid #dddddd' }}
            src="https://www.flipcause.com/widget/NjMxMg=="
            width="300"
            height="150"
          ></iframe>
        </div>
        <PlannedGiving />
        {/* <SupportForm /> */}
      </div>
    </Layout>
  );
};

export default SupportPage;
