/** @jsx jsx */
import { jsx } from 'theme-ui';

const SectionHeader = ({ heading, styles }) => {
  return (
    <div sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...styles }}>
      <h3
        sx={{
          variant: 'text.xl',
          color: '#666666',
          mx: 'auto',
          textAlign: 'center',
          my: '1rem',
        }}
      >
        {heading}
      </h3>
    </div>
  );
};

export default SectionHeader;
